<template>
  <div v-loading="loading">
    <div class="title flex justify-between items-center"
      >忘记密码
      <el-button type="primary" link @click="emit('changeLoginType', 'pwd')"
        >返回登录</el-button
      >
    </div>
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" size="large">
      <el-form-item prop="phone">
        <el-input
          v-model="ruleForm.phone"
          placeholder="请输入手机号码"
          autocomplete="off"
          maxlength="11"
          prefix-icon="iphone"
        />
      </el-form-item>
      <el-form-item prop="code">
        <el-input
          v-model="ruleForm.code"
          autocomplete="off"
          placeholder="请输入验证码"
          maxlength="6"
          prefix-icon="Lock"
        >
          <template #suffix>
            <el-button
              v-if="time == 60"
              type="primary"
              :loading="btnLoading"
              link
              @click="getCode"
              >获取验证码</el-button
            >
            <span v-else class="color-#333 fs-14"
              >{{ time }}s后重新获验证码</span
            >
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="ruleForm.password"
          type="password"
          show-password
          autocomplete="off"
          size="large"
          placeholder="请输入新密码"
          maxlength="15"
          prefix-icon="Lock"
        />
      </el-form-item>
      <el-form-item prop="password1">
        <!-- <span class="color-#999 fs-16">请输入6-15位数字或字母</span> -->
        <el-input
          v-model="ruleForm.password1"
          type="password"
          show-password
          autocomplete="off"
          size="large"
          placeholder="重复新密码"
          maxlength="15"
          prefix-icon="Lock"
        />
      </el-form-item>
    </el-form>
    <el-button
      class="w-full"
      color="#176EF7"
      type="primary"
      size="large"
      @click="submitForm(ruleFormRef)"
      >确定修改</el-button
    >
  </div>
</template>

<script setup>
import { isMobile, isCode, isPassword } from '@/utils/validate'
import { forgetApi, sendSmsApi } from '@/api/user.js'
import { useUserStore } from '@/store/modules/user'

const emit = defineEmits(['changeLoginType'])
const userStore = useUserStore()
const loading = ref(false)
const ruleFormRef = ref()
// 表单数据
const ruleForm = ref({
  phone: '',
  password: '',
  password1: '',
  code: '',
  scene: 'mod_pwd',
})
// 表单校验规则
const rules = {
  phone: [
    {
      required: true,
      message: '请输入手机号码',
      trigger: 'blur',
    },
    {
      validator: (rule, value) => {
        return isMobile(value)
      },
      trigger: 'blur',
      message: '请输入正确的手机号码',
    },
  ],
  code: [
    {
      required: true,
      message: '请输入短信验证码',
      trigger: 'blur',
    },
    {
      validator: (rule, value) => {
        return isCode(value)
      },
      trigger: 'blur',
      message: '请输入6位验证码',
    },
  ],
  password: [
    {
      required: true,
      message: '请输入新密码',
      trigger: 'blur',
    },
    {
      validator: (rule, value) => {
        return isPassword(value)
      },
      trigger: 'blur',
      message: '请输入6-15位密码',
    },
  ],
  password1: [
    {
      required: true,
      message: '请输入新密码',
      trigger: 'blur',
    },
    {
      validator: (rule, value) => {
        return isPassword(value)
      },
      trigger: 'blur',
      message: '请输入6-15位密码',
    },
    {
      validator: (rule, value) => {
        return ruleForm.value.password === value
      },
      message: '两次输入的密码不一致',
      trigger: ['blur'],
    },
  ],
}
const time = ref(60)
const timer = ref(null)
const btnLoading = ref(false)
function getCode() {
  if (timer.value) return
  if (!ruleFormRef.value) return
  try {
    ruleFormRef.value.validateField('phone', async errorsRes => {
      if (!errorsRes) return
      btnLoading.value = true
      await sendSmsApi(ruleForm.value)
      startCountdown()
    })
  } catch (error) {
    btnLoading.value = false
  }
}
// 倒计时
function startCountdown() {
  timer.value = setInterval(function () {
    time.value--
    if (time.value === 0) {
      clearInterval(timer.value)
      timer.value = null
      time.value = 60
      btnLoading.value = false
    }
  }, 1000)
}
// 登录
const submitForm = formEl => {
  if (!formEl) return
  formEl.validate(async valid => {
    if (valid) {
      try {
        loading.value = true
        await forgetApi(ruleForm.value)
        ElMessage({
          message: '密码修改成功',
          type: 'success',
        })
        emit('changeLoginType', 'pwd')
        loading.value = false
      } catch (error) {
        loading.value = false
      }
    } else {
      return false
    }
  })
}
</script>

<style scoped lang="scss">
.title {
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}
</style>
