<template>
  <div v-loading="loading">
    <div class="flex justify-between items-center mb-16">
      <div class="title">欢迎使用，验证码登录</div>
      <el-button
          type="primary"
          link
          @click="emit('changeLoginType', 'register')"
      >注册账号
      </el-button
      >
    </div>
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" size="large">
      <el-form-item prop="phone">
        <el-input
            v-model="ruleForm.phone"
            placeholder="请输入手机号码"
            autocomplete="off"
            maxlength="11"
            prefix-icon="iphone"
        />
      </el-form-item>
      <el-form-item prop="code">
        <el-input
            v-model="ruleForm.code"
            autocomplete="off"
            placeholder="请输入验证码"
            maxlength="6"
            prefix-icon="Lock"
        >
          <template #suffix>
            <el-button
                v-if="time == 60"
                type="primary"
                :loading="btnLoading"
                link
                @click="getCode"
            >
              获取验证码
            </el-button>
            <span v-else class="color-#333 fs-14">
              {{ time }}s后重新获验证码
            </span>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <div class="flex justify-between items-center mb-34">
      <el-button type="primary" link @click="emit('changeLoginType', 'pwd')">
        手机号密码登录
      </el-button>
      <el-button type="primary" link @click="emit('changeLoginType', 'forget')">
        忘记密码？
      </el-button>
    </div>
    <el-button
        class="w-full"
        color="#176EF7"
        type="primary"
        size="large"
        @click="submitForm(ruleFormRef)"
    >登录
    </el-button
    >
    <div
        v-if="props.type === 'complex'"
        class="flex flex-col justify-center items-center mt-42 mx-auto cursor-pointer w-70"
        @click="emit('changeLoginType', 'weChat')"
    >
      <img src="@/assets/img/icon-weChat.png" class="w-52 h-52 mb-8"/>
      <span class="color-#333 fs-16">微信登录</span>
    </div>
    <div
        v-if="props.type === 'simplify'"
        class="flex justify-center items-center mt-12 mx-auto cursor-pointer w-140"
        @click="emit('changeLoginType', 'weChat')"
    >
      <img src="@/assets/img/icon-weChat.png" class="w-32 h-32 mr-8"/>
      <span class="color-#333 fs-16">微信登录</span>
    </div>
  </div>
</template>

<script setup>
import {isMobile, isCode} from '@/utils/validate'
import {smsLoginApi, sendSmsApi} from '@/api/user.js'
import {useUserStore} from '@/store/modules/user'

const emit = defineEmits(['changeLoginType'])
const loading = ref(false)
const props = defineProps({
  type: {
    type: String,
    default: 'complex', // simplify 精简版 complex 复杂版
  },
})
const userStore = useUserStore()
const ruleFormRef = ref()
// 表单数据
const ruleForm = ref({
  phone: '',
  code: '',
  scene: 'login',
})
// 表单校验规则
const rules = {
  phone: [
    {
      required: true,
      message: '请输入手机号码',
      trigger: 'blur',
    },
    {
      validator: (rule, value) => {
        return isMobile(value)
      },
      trigger: 'blur',
      message: '请输入正确的手机号码',
    },
  ],
  code: [
    {
      required: true,
      message: '请输入短信验证码',
      trigger: 'blur',
    },
    {
      validator: (rule, value) => {
        return isCode(value)
      },
      trigger: 'blur',
      message: '请输入6位验证码',
    },
  ],
}
const time = ref(60)
const timer = ref(null)
const btnLoading = ref(false)

function getCode() {
  if (timer.value) return
  if (!ruleFormRef.value) return
  try {
    ruleFormRef.value.validateField('phone', async errorsRes => {
      if (!errorsRes) return
      btnLoading.value = true
      await sendSmsApi(ruleForm.value)
      startCountdown()
    })
  } catch (error) {
    btnLoading.value = false
  }
}

// 倒计时
function startCountdown() {
  timer.value = setInterval(function () {
    time.value--
    if (time.value === 0) {
      clearInterval(timer.value)
      timer.value = null
      time.value = 60
      btnLoading.value = false
    }
  }, 1000)
}

// 登录
const submitForm = formEl => {
  if (!formEl) return
  formEl.validate(async valid => {
    if (valid) {
      try {
        loading.value = true
        const {data} = await smsLoginApi(ruleForm.value)
        userStore.setToken(data.token)
        userStore.setShowLogin(false)
        loading.value = false
      } catch (error) {
        loading.value = false
      }
    } else {
      return false
    }
  })
}
</script>

<style scoped lang="scss">
.title {
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  font-weight: bold;
}
</style>
