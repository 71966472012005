export default [
    {
        //考试列表
        path: 'exam',
        name: 'exam',
        component: () => import('@/views/exam/index.vue'),
        meta: {title: '考试报名', affix: true, demoTitle: '建筑行业职业技能服务平台'},
    },
    {
        //开始考试
        path: '/ExamDetails',
        name: 'ExamDetails',
        component: () => import('@/views/exam/ExamDetails.vue'),
        meta: {title: '考试详情', affix: true, demoTitle: '建筑行业职业技能服务平台'},
    }
]
