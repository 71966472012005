<template>
  <div v-loading="loading">
    <div class="mb-20 bg-#fff py-40 h-300 box-border">
      <div class="w-1360 mx-auto flex justify-start gap-x-30">
        <img
          :src="
            (courseInfo.baseInfo && courseInfo.baseInfo.itemCover) ||
            'https://res.hangxintong.cn/uniXkb/backgroundImage/course_background.png'
          "
          class="w-400 h-250"
        />
        <div class="flex-1">
          <div class="color-#333333 fs-28 fontWeight-400 mb-48">{{
            courseInfo.baseInfo && courseInfo.baseInfo.itemName
          }}</div>
          <!-- <div class="my-16">
            <span
              class="fs-14 color-#fff bg-#0060EF px-14 py-4 borderRadius-20"
              >{{ courseInfo.baseInfo.tagName }}</span
            >
            
          </div> -->
          <div class="fs-12 color-#999999 mb-40"
            >价格：<span class="fs-28 fontWeight-700 color-#FF0000"
              ><span class="fs-12">￥</span
              >{{ courseInfo.baseInfo.price }}</span
            ></div
          >
          <div class="flex justify-start gap-x-40">
            <el-button
              type="primary"
              color="#0060EF"
              size="large"
              class="w-176"
              @click="toPay"
              >报名学习</el-button
            >
            <el-button
              v-if="!courseInfo.isCollect"
              plain
              size="large"
              @click="collect"
            >
              <template #icon>
                <el-icon size="20"><Star /></el-icon>
              </template>
              <span>收藏</span>
            </el-button>
            <el-button v-else plain size="large" @click="cancelCollect">
              <template #icon>
                <el-icon color="#FADB14" size="20"><StarFilled /></el-icon>
              </template>
              <span>已收藏</span>
            </el-button>
            <el-popover
              placement="right"
              :width="200"
              trigger="click"
              @show="getQrCode"
            >
              <template #reference>
                <el-button plain icon="Share" size="large">分享</el-button>
              </template>
              <div
                v-loading="qrCodeLoading"
                class="flex flex-col justify-center items-center"
              >
                <div class="fs-16 color-#666">分享</div>
                <div class="h-160"
                  ><img v-if="qrCode" :src="qrCode" class="w-160 h-160"
                /></div>
                <div class="fs-12 color-#666 flex items-center"
                  ><img
                    src="@/assets/img/icon-weChat.png"
                    class="w-24 h-24 mr-8"
                  />微信扫码</div
                >
              </div>
            </el-popover>
          </div>
        </div>
        <el-button plain @click="router.go(-1)">返回</el-button>
      </div>
    </div>
    <div class="w-1360 mx-auto bg-#fff py-24 px-80 box-border mb-36">
      <el-tabs v-model="tabName" class="demo-tabs" @tab-click="changeTab">
        <el-tab-pane label="目录" name="1">
          <div class="color-#333333 fs-24 fontWeight-700 mt-9 mb-16">{{
            courseInfo.baseInfo && courseInfo.baseInfo.itemName
          }}</div>
          <div class="color-#999999 fs-16 mb-16"
            >共{{ totalNum }}章，{{ classHours }}课时，{{
              targetHours
            }}学时达标</div
          >
          <el-collapse v-if="courseList.length > 0" v-model="activeNames">
            <el-collapse-item
              :title="course.courseName"
              v-for="(course, index) in courseList"
              :key="index"
              :name="index"
            >
              <div
                v-for="(chapter, idx) in course.chapters"
                class="u-collapse-content"
                :key="idx"
              >
                <div class="fontSize-16 text-ellipsis-2 mb-20">{{
                  chapter.chapterTitle
                }}</div>
                <div
                  v-for="(section, ids) in chapter.section"
                  :key="ids"
                  class="color-#171A1D fontSize-16 mb-20 text-ellipsis-2 cursor-pointer courseName"
                >
                  {{ section.sectionTitle }}</div
                >
              </div>
            </el-collapse-item>
          </el-collapse>
          <el-empty
            v-else
            description="暂无课程"
            :image-size="100"
            image="https://res.hangxintong.cn/uniXkb/status/s3.png"
          />
        </el-tab-pane>
        <el-tab-pane label="介绍" name="2">
          <div
            v-if="courseInfo.baseInfo && courseInfo.baseInfo.itemIntroduce"
            class="fs-20 mt-9"
            style="line-height: 1.5em"
            v-html="courseInfo.baseInfo.itemIntroduce"
          />
          <el-empty
            v-else
            description="暂无介绍"
            :image-size="100"
            image="https://res.hangxintong.cn/uniXkb/status/s3.png"
          />
        </el-tab-pane>
        <el-tab-pane v-if="false" label="评论" name="3">
          <div
            v-if="commentList.length > 0"
            class="fontSize-24 color-#333 mb-25 fw-700"
            >评论({{ commentList.length }})</div
          >
          <div v-if="commentList.length > 0">
            <div
              v-for="(item, index) in commentList"
              :key="index"
              class="flex gap-x-24"
            >
              <img
                src="@/assets/img/hqt-logo.png"
                class="w-48 h-48 borderRadius-50"
              />
              <div style="border-bottom: 1px solid #f1f1f1">
                <div class="fontSize-18 mb-5 flex items-center">
                  <div class="color-#3E454D mr-25 fontWeight-400">张三</div>
                  <el-rate v-model="item.rateValue" size="large" disabled />
                </div>
                <div class="color-#9FA9B1 mb-19">2022-03-22 21:22:34</div>
                <div class="color-#3E454D fontSize-18 mb-27"
                  >自愿申报内蒙古自治区建筑业协会工程项目管理与建造师分会审核。</div
                >
              </div>
            </div>
          </div>
          <el-empty
            v-else
            description="暂无评论"
            :image-size="100"
            image="https://res.hangxintong.cn/uniXkb/status/s3.png"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
      v-model="showRepeatTip"
      title="提示"
      width="480"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="mx-22 py-12 px-16">
        <div class="color-#333 fs-14 ml-20 mt-40 mb-50"
          >您已购买过该课程，是否重复购买课程？</div
        >
      </div>
      <template #footer>
        <div class="flex justify-start gap-x-24 ml-20 mb-13 py-12 px-16">
          <el-button type="primary" @click="continuePay">继续购买</el-button>
          <el-button plain @click="showRepeatTip = false"> 取消 </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  getTrainCourseInfoApi,
  userCollectApi,
  getPurchasedStateApi,
} from '@/api/course.js'
import { postFormData } from '@/api/FormApi'
import { getCourseCheckFormApi } from '@/api/learn.js'
import { cancelSelectListApi } from '@/api/user.js'
import { useUserStore } from '@/store/modules/user'
import { StarFilled } from '@element-plus/icons-vue'
import { useQrCode } from '@/hook/useQrCode'
const { createQrCode } = useQrCode()
const router = useRouter()
const route = useRoute()
const userStore = useUserStore()
const tabName = ref('1')
const loading = ref(false)
const showRepeatTip = ref(false) // 重复购买提示
const isRepeat = ref(true)
const courseInfo = ref({
  baseInfo: {},
  itemConfig: {},
})
const activeNames = ref([])
// 课程目录
const courseList = ref([])
// 评论列表数据
const commentList = ref([])
// 总章节
const totalNum = ref(0)
// 总课时
const classHours = ref(0)
// 达标学时
const targetHours = computed(() => {
  let num = 0
  if (courseInfo.value.itemConfig.requiredTarget != null) {
    num += courseInfo.value.itemConfig.requiredTarget
  }
  if (courseInfo.value.itemConfig.searchTarget != null) {
    num += courseInfo.value.itemConfig.searchTarget
  }
  return num
})
const qrCode = ref('') // 分享二维码
const qrCodeLoading = ref(false)
onMounted(() => {
  console.log(route.params.id)
  getTrainCourseInfo()
})
function changeTab(tab) {
  console.log(tab)
}
// 课程简介
function getTrainCourseInfo() {
  loading.value = true
  getTrainCourseInfoApi(route.params.id)
    .then(res => {
      courseInfo.value = res.data

      let arr = []
      if (res.data.requiredClass) {
        res.data.requiredClass.forEach(item => {
          arr.push(item)
        })
      }
      if (res.data.searchSplit) {
        res.data.searchSplit.forEach(item => {
          arr.push(item)
        })
      }
      arr.forEach((it, index) => {
        totalNum.value += it && it.chapters && it.chapters.length
        it &&
          it.chapters &&
          it.chapters.forEach(item => {
            item.section.forEach(j => {
              classHours.value += j.classHours
            })
          })
        // 默认展开全部目录
        activeNames.value.push(index)
      })
      courseList.value = arr

      loading.value = false
    })
    .catch(() => {
      loading.value = false
    })
}
// 报名课程
function toPay() {
  if (!userStore.getToken) {
    userStore.setShowLogin(true)
    return
  }
  loading.value = true
  // 检查是否重复购买
  getPurchasedStateApi(courseInfo.value.baseInfo.id)
    .then(res => {
      loading.value = false
      isRepeat.value = res.data
      if (isRepeat.value) {
        // 重复报名
        showRepeatTip.value = true
        return
      } else {
        showRepeatTip.value = false
        // 查询表单
        const data = {
          resourceType: 'project',
          resourceId: route.params.id,
        }
        getCourseCheckFormApi(data).then(res => {
          showRepeatTip.value = false
          if (res.data.needFill) {
            // 报名需要填写表单
            router.push(
              `/CustomerForm/index?FormId=${res.data.formId}&type=project&resourceId=${route.params.id}`
            )
          } else {
            const data = {
              resourceType: 'project',
              resourceId: route.params.id,
            }
            postFormData(data).then(res => {
              if (res.code == 1000) {
                router.push(
                  '/placeOrder?id=' +
                    route.params.id +
                    '&enrollId=' +
                    res.data.id
                )
              } else {
                ElMessage({ message: res.msg, type: 'error' })
              }
            })
            // router.push('/placeOrder?id=' + route.params.id)
          }
        })
      }
    })
    .catch(err => {
      console.log(err)
      loading.value = false
    })
}
// 重复购买 继续购买
function continuePay() {
  // 查询表单
  const data = {
    resourceType: 'project',
    resourceId: route.params.id,
  }
  getCourseCheckFormApi(data).then(res => {
    showRepeatTip.value = false
    if (res.data.needFill) {
      // 报名需要填写表单
      router.push(
        `/CustomerForm/index?FormId=${res.data.formId}&type=project&resourceId=${route.params.id}`
      )
    } else {
      const data = {
        resourceType: 'project',
        resourceId: route.params.id,
      }
      postFormData(data).then(res => {
        if (res.code == 1000) {
          router.push(
            '/placeOrder?id=' + route.params.id + '&enrollId=' + res.data.id
          )
        } else {
          ElMessage({ message: res.msg, type: 'error' })
        }
      })
    }
    // }
  })
}
// 收藏
function collect() {
  // 未登录
  if (!userStore.getToken) {
    userStore.setShowLogin(true)
    return
  }
  const data = {
    resourceType: 'project',
    resourceId: route.params.id,
  }
  userCollectApi(data).then(res => {
    console.log(res)
    ElMessage.success('收藏成功')
    courseInfo.value.isCollect = true
  })
}
// 取消收藏
function cancelCollect() {
  // 未登录
  if (!userStore.getToken) {
    userStore.setShowLogin(true)
    return
  }
  cancelSelectListApi(route.params.id).then(res => {
    ElMessage.success('取消收藏')
    courseInfo.value.isCollect = false
  })
}
async function getQrCode() {
  qrCodeLoading.value = true
  let url =
    import.meta.env.VITE_APP_COURSES_SHARE_URL +
    `/courseShare?id=${route.params.id}`
  if (userStore.agentInfo && userStore.agentInfo.id) {
    url += `&agentId=${userStore.agentInfo.id}`
  }
  const img = await createQrCode(url)
  qrCode.value = img
  qrCodeLoading.value = false
}
</script>

<style scoped lang="scss">
.courseName:hover {
  color: #0060ef;
}
:deep(.el-button + .el-button) {
  margin-left: 0;
}
:deep(.el-tabs__item) {
  // font-size: 24px;
  font-size: 18px;
  padding: 0 40px;
}
:deep(.el-tabs__active-bar) {
  height: 4px;
  border-radius: 3px;
}
:deep(.el-collapse-item__header) {
  font-size: 20px;
}
:deep(.el-dialog) {
  padding: 0;
}
:deep(.el-dialog__header) {
  display: flex;
  align-items: center;
  padding: 0 0 0 16px;
  height: 40px;
  font-size: 14px;
  color: #333333;
}
</style>
