<template>
  <div v-loading="loading">
    <div class="flex justify-between items-center mb-16">
      <div class="title"> 欢迎使用，手机号密码登录 </div>
      <el-button
        type="primary"
        link
        @click="emit('changeLoginType', 'register')"
        >注册账号</el-button
      >
    </div>
    <el-form
      ref="ruleFormRef"
      style="max-width: 600px"
      :model="ruleForm"
      :rules="rules"
      size="large"
    >
      <el-form-item prop="username">
        <el-input
          v-model="ruleForm.username"
          placeholder="请输入手机号码"
          size="large"
          autocomplete="off"
          maxlength="11"
          prefix-icon="iphone"
        />
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="ruleForm.password"
          type="password"
          show-password
          autocomplete="off"
          size="large"
          placeholder="请输入登录密码"
          maxlength="15"
          prefix-icon="Lock"
        />
      </el-form-item>
    </el-form>
    <div class="flex justify-between items-center mb-34">
      <el-button type="primary" link @click="emit('changeLoginType', 'code')"
        >验证码登录</el-button
      >
      <el-button type="primary" link @click="emit('changeLoginType', 'forget')"
        >忘记密码？</el-button
      >
    </div>
    <el-button
      class="w-full"
      color="#176EF7"
      type="primary"
      size="large"
      @click="submitForm(ruleFormRef)"
      >登录</el-button
    >
    <div
      v-if="props.type === 'complex'"
      class="flex flex-col justify-center items-center mt-42 mx-auto cursor-pointer w-70"
      @click="emit('changeLoginType', 'weChat')"
    >
      <img src="@/assets/img/icon-weChat.png" class="w-52 h-52 mb-8" />
      <span class="color-#333 fs-16">微信登录</span>
    </div>
    <div
      v-if="props.type === 'simplify'"
      class="flex justify-center items-center mt-12 mx-auto cursor-pointer w-140"
      @click="emit('changeLoginType', 'weChat')"
    >
      <img src="@/assets/img/icon-weChat.png" class="w-32 h-32 mr-8" />
      <span class="color-#333 fs-16">微信登录</span>
    </div>
  </div>
</template>

<script setup>
import { isMobile, isPassword } from '@/utils/validate'
import { accountLoginApi } from '@/api/user'
import { useUserStore } from '@/store/modules/user'
const emit = defineEmits(['changeLoginType'])
const userStore = useUserStore()
const loading = ref(false)
const props = defineProps({
  type: {
    type: String,
    default: 'complex', // simplify 精简版 complex 复杂版
  },
})
const ruleFormRef = ref()
// 表单数据
const ruleForm = ref({
  username: '',
  password: '',
})
// 表单校验规则
const rules = {
  username: [
    {
      required: true,
      message: '请输入手机号码',
      trigger: 'blur',
    },
    {
      validator: (rule, value) => {
        return isMobile(value)
      },
      trigger: 'blur',
      message: '请输入正确的手机号码',
    },
  ],
  password: [
    {
      required: true,
      message: '请输入登录密码',
      trigger: 'blur',
    },
    {
      validator: (rule, value) => {
        return isPassword(value)
      },
      trigger: 'blur',
      message: '请输入6-15位登录密码',
    },
  ],
}
// 登录
const submitForm = formEl => {
  if (!formEl) return
  formEl.validate(valid => {
    if (valid) {
      console.log('submit!', ruleForm.value)
      loading.value = true
      accountLoginApi(ruleForm.value)
        .then(res => {
          userStore.setToken(res.data.token)
          userStore.setShowLogin(false)
          loading.value = false
        })
        .catch(() => {
          loading.value = false
        })
    } else {
      return false
    }
  })
}
</script>

<style scoped lang="scss">
.title {
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  font-weight: bold;
}
</style>
