export default [
    {
        path: 'studyCenter',
        name: 'studyCenter',
        component: () => import('@/views/studyCenter/index.vue'),
        meta: {title: '学考中心', affix: true, demoTitle: '建筑行业职业技能服务平台'},
    },
    {
        path: 'studyCenter/studyDetail/:id',
        name: 'studyDetail',
        component: () => import('@/views/studyCenter/studyDetail.vue'),
        meta: {title: '详情', affix: true, demoTitle: '建筑行业职业技能服务平台'},
    },
    {
        path: 'studyCenter/guideForm/:id',
        name: 'guideForm',
        component: () => import('@/views/studyCenter/guideForm.vue'),
        meta: {title: '操作指引', affix: true, demoTitle: '建筑行业职业技能服务平台'},
    },
    {
        path: 'studyCenter/guide',
        name: 'guide',
        component: () => import('@/views/studyCenter/guide.vue'),
        meta: {title: '操作指引', affix: true, demoTitle: '建筑行业职业技能服务平台'},
    },
    {
        path: 'CustomerForm/index',
        name: 'CustomerForm',
        component: () => import('@/views/CustomerForm/index.vue'),
        meta: {title: '简易表单', affix: true, demoTitle: '建筑行业职业技能服务平台'},
    }
]
