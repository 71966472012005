import http from '@/libs/http/index'

/**
 * @description 分页检索培训资源池列表
 * @param {number} pageNum 页数
 * @param {number} pageSize 条数
 * @param {string} name 关键字
 * @param {string} isAsc 排序
 *
 * @return {Object}
 */
export function trainProjectExamListApi(data) {
    return http.get('pc/trainProjectExam/enroll/pageList', {params: data})
}

/**
 * @description 根据id获取培训项目信息
 * @param {number} id
 * @return {Object}
 */
export function courseInfoApi(id) {
    return http.get('pc/training/' + id)
}

/**
 * @description 获取培训任务列表
 * @param {number} pageNum 页数
 * @param {number} pageSize 条数
 * @return {Object}
 */
export function taskListApi(data) {
    return http.get('pc/training/task/list', {params: data})
}

/**
 * @description 检查是否需要填写报名表单
 * @param {String} resourceType
 * @param {String} resourceId
 *
 */
export function getCourseCheckFormApi({resourceType, resourceId}) {
    return http.get(
        `pc/trainProjectExam/enroll/checkForm?resourceType=${resourceType}&resourceId=${resourceId}`
    )
}

/**
 * @description 获取课程任务详情
 * @param {String} id
 *
 */
export function getInfoApi() {
    return http.get(
        `pc/trainProjectExam/enroll/checkForm?resourceType=${resourceType}&resourceId=${resourceId}`
    )
}

/**
 * @description 获取任务详情
 * @param {String} taskId
 * @param {String} taskType
 *
 */
export function getTaskDetailApi({taskId, taskType}) {
    return http.get(
        `pc/training/task/detail?taskId=${taskId}&taskType=${taskType}`
    )
}

/**
 * @description 获取视频播放URL
 * @param { String } vid
 */
export function getaLiPlayUrlApi(vid) {
    return http.get(`pc/trainItem/getPlayVideoUrl?vid=${vid}`)
}

// 上传学习进度
/**
 * @description 上传学习进度
 * @param { String } "trainId": "string",
 "sectionId": "string",
 "time": 0,
 "status": 0,
 "cate": 0,
 "address": "string",
 "courseId": "string",
 "chapterId": "string",
 "videoToken": "string",
 "complete": "string"
 * @param { String } sectionId
 */
export function addStudyLog(data) {
    return http.post('pc/studyLog', data)
}

/**
 * @description 提交UUID
 * @param { String } uuid
 */
export function submitUUID(uuid) {
    return http.post('pc/setVideoToken?videoToken=' + uuid)
}

/**
 * @description 单点登录检查
 * @param { String } videoToken  uuid
 */
export function postCheckLogin(uuid) {
    return http.post(`pc/studyLogCheckLogin?videoToken=${uuid}`)
}

/**
 * @description 直接购买课程 部署
 * @param { String } taskId
 */
export function getDeployTaskApi(taskId) {
    return http.get(`pc/training/task/deploy/${taskId}`)
}

// ---------------

/**
 * @description 执行人认领任务
 * @param { String } id
 */
export function putClaimTaskApi(id) {
    return http.put(`pc/training/claim/${id}`)
}

/**
 * @description 检索指定培训任务下的指定任务环节详情
 * @param { String } taskId
 */
export function getDetailWithStepApi(taskId) {
    return http.get(`pc/training/detailWithStep?taskId=${taskId}`)
}

/**
 * @description 部署培训资源
 * @param { String } taskId
 */
export function postStepRes(stepResId) {
    return http.post(`pc/training/stepRes?stepResId=${stepResId}`)
}

/**
 * @description 将指定培训任务下的指定培训环节设置为'完成'状态
 * @param { String } stepId
 */
export function putStepFinish(stepId) {
    return http.put(`pc/training/step/${stepId}/finish`)
}

/**
 * @description 提交表单 【2023-11-13】
 * @param { String } customFormId  自定义表单id
 * @param { String } ext  填写信息；k：表单元素id，v：用户填写值
 * @return { Boolean }
 */
export function postFormData(data) {
    return http.put(`pc/training/step/res`, data)
}

/**
 * @description 获取自定义表单
 * @param { String } formId
 */
export function getElementApi(formId) {
    return http.get(`pc/custom/form/getElement/${formId}`)
}

/**
 * @description 获取学习进度
 * @param { String } formId
 */
export function getLearningProgressApi(id) {
    return http.get(`pc/training/learningProgress`, {
        params: {
            trainingProjectId: id,
            // hxtItemId:id
        },
    })
}

//培训更新学时
export function taskdetail(data) {
    return http.get(`pc/training/task/detail`, {
        params: {
            taskId: data.taskId,
            taskType: data.taskType
        }
    })
}
