<template>
  <div class="footer">
    <div class="mb-20"
    >
      版权所有@行企通 | <span @click="beianhao" style="cursor: pointer">蒙ICP备2020004806号-3</span> | 蒙公网安备
      15010502000104号
      <!--蒙ICP备18003691号-1-->
    </div>
    <div>行信通科技（内蒙古）有限责任公司</div>
  </div>
</template>

<script setup>
function beianhao() {
  window.open('https://beian.miit.gov.cn/')
}
</script>

<style scoped lang="scss">
.footer {
  background-color: rgba(44, 118, 245, 1);
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
}
</style>
