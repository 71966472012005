<template>
  <div>
    <div class="title">欢迎使用，微信扫码登录 </div>
    <div
      v-if="clickLogin"
      class="mx-auto relative flex justify-center items-center"
      :class="
        props.type == 'simplify' ? 'w-200 h-200 mb-34' : 'w-242 h-242 mb-42'
      "
    >
      <div
        class="flex flex-col justify-center items-center cursor-pointer"
        @click="handleClick"
      >
        <img src="@/assets/img/icon-wx.png" class="w-74 h-74 mb-23" />
        <div
          class="w-192 h-44 flex items-center justify-center color-#fff fw-600 bg-#39B54A borderRadius-8"
          >使用微信扫码登录</div
        >
      </div>
    </div>
    <div
      v-else
      v-loading="loading"
      class="mx-auto relative"
      :class="
        props.type == 'simplify' ? 'w-200 h-200 mb-34' : 'w-242 h-242 mb-42'
      "
    >
      <img
        v-if="qrCodeUrl"
        :src="qrCodeUrl"
        @load="loading = false"
        :class="props.type == 'simplify' ? 'w-200 h-200' : 'w-242 h-242'"
      />
      <div
        v-if="refreshStatus"
        class="absolute top-0 left-0 w-100% h-100% bg-#00000099 flex justify-center items-center"
      >
        <!-- 刷新 -->
        <!-- <div class="w-130 h-100 bg-#ffffff flex justify-center items-center"> -->
        <el-button type="info" class="h-80 fs-16" @click="getQrCode"
          >二维码已失效，刷新</el-button
        >
        <!-- </div> -->
      </div>
    </div>
    <div class="flex justify-center items-center">
      <el-button type="primary" link @click="emit('changeLoginType', 'pwd')"
        >手机号密码登录</el-button
      >
      <el-divider direction="vertical" />
      <el-button type="primary" link @click="emit('changeLoginType', 'code')"
        >手机号验证码登录</el-button
      >
      <el-divider direction="vertical" />
      <el-button
        type="primary"
        link
        @click="emit('changeLoginType', 'register')"
        >注册账号</el-button
      >
    </div>
  </div>
</template>

<script setup>
import { getLoginQrCode, checkLoginQrCodeState } from '@/api/home'
import { useUserStore } from '@/store/modules/user'

const emit = defineEmits(['changeLoginType'])
const userStore = useUserStore()
const props = defineProps({
  type: {
    type: String,
    default: 'complex', // simplify 精简版 complex 复杂版
  },
})
const clickLogin = ref(true) // 点击登录
const qrCodeUrl = ref('') // 二维码地址
const timer = ref(null) // 定时器
const refreshStatus = ref(false) // 刷新状态
const loading = ref(false)
const sceneId = ref(null)

onUnmounted(() => {
  clearInterval(timer.value)
})
function handleClick() {
  clickLogin.value = false
  getQrCode()
}
function getQrCode() {
  loading.value = true
  getLoginQrCode().then(res => {
    qrCodeUrl.value = res.data.mpTmpQrCode
    sceneId.value = res.data.sceneId
    refreshStatus.value = false
    timer.value = setInterval(() => {
      startCheck()
    }, 3000)
    setTimeout(() => {
      refreshStatus.value = true
      clearInterval(timer.value)
    }, 30000)
  })
}
// 检查扫码状态
function startCheck() {
  checkLoginQrCodeState(sceneId.value).then(res => {
    console.log(res)
    if (res.data.isBindMobile == false) {
      // 未绑定手机号
      // console.log('进来了')
      emit('changeLoginType', 'bindMobile', res.data.userId)
    } else {
      // 已绑定手机号
      // console.log('进来了22222')
      // clearInterval(timer.value)
      if (res.data.token) {
        userStore.setToken(res.data.token)
        userStore.setShowLogin(false)
      } else if (res.data.isSetPassword == false) {
        console.log('未设置密码')
        // 绑定手机号，但未设置密码
        emit('changeLoginType', 'bindMobile', res.data.userId, res.data.mobile)
      }
    }
  })
}
</script>

<style scoped lang="scss">
.title {
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}
</style>
