import { defineStore, acceptHMRUpdate } from 'pinia'
import { APP_NAME } from '@/define/constants'
import store from '../index'
import { userInfoApi, postAgentInfoByHref } from '@/api/user'
const STORE_KEY = APP_NAME + '-USER'

export const useUserStore = defineStore({
  id: STORE_KEY,
  state: (): UserState => ({
    token: '',
    userInfo: null,
    preHistory: '',
    showLogin: false,
    currentNote: '',
    agentInfo: {},
  }),
  getters: {
    getToken: (state: UserState): string => {
      return state.token
    },
    hasLogged: (state: UserState): boolean => !!state.token,
    //TODO:需要获取用户信息后使用此逻辑 !!state.token && !!state.userInfo?.userId,
    getUserInfo: (state: UserState): UserInfo | null => {
      return state.userInfo as UserInfo
    },
    getShowLoginStatus: (state: UserState): boolean => {
      return state.showLogin
    },
  },
  actions: {
    async logout() {
      // await loginApi.logout()
      this.removeToken()
      this.setUserInfo()
      // window.location.reload()
    },
    setToken(data: string) {
      this.token = data
    },
    removeToken() {
      this.token = ''
    },
    setUserInfo(data?: object) {
      if (!data) {
        this.userInfo = null
        return
      }
      if (!this.userInfo) {
        this.userInfo = {}
      }
      Object.assign(this.userInfo, data || {})
    },
    // async getUserInfo() {
    //   const userRes = await userInfoApi()
    //   this.setUserInfo(userRes?.data)
    // },
    removeUserInfo() {
      this.userInfo = null
    },
    async initUserInfo() {
      const userRes = await userInfoApi()
      this.setUserInfo(userRes?.data.user)
      return Promise.resolve()
    },
    setHistory(history: string) {
      this.preHistory = history
    },
    setShowLogin(showLogin: boolean) {
      this.showLogin = showLogin
    },
    // 保存currentNote
    setCurrentNote(currentNote: string) {
      this.currentNote = currentNote
    },
    // 域名获取代理商信息
    getAgentInfo() {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await postAgentInfoByHref(location.host)
          this.agentInfo = data || {}
          // 检测用户代理字符串
          const userAgent = navigator.userAgent
          // 判断是否为移动端
          const isMobile =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              userAgent
            )
          if (isMobile) {
            // 执行移动端逻辑
            location.href = data
              ? '//' + data.h5Domain
              : '//' + import.meta.env.VITE_APP_XKBH5_URL
          }
          resolve(data)
        } catch (e) {
          reject(e)
        }
      })
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: sessionStorage, // $localStorage,
        paths: ['token', 'currentNote', 'agentInfo'], //可以选择保存的字段  其余的不保存
      },
      // {
      //   storage: sessionStorage,
      //   paths: ['preHistory'], //可以选择保存的字段  其余的不保存
      // },
    ],
  },
})
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
export const useUserStoreWithout = () => {
  return useUserStore(store)
}
