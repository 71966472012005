<template>
  <div class="header">
    <div class="content">
      <div class="left">
        <div class="h-69 w-325 flex justify-center items-center mr-80">
          <img
            src="https://res.hangxintong.cn/hongde/hongDe-logo.png"
            alt=""
            class="h-69 w-325"
          />
        </div>
      </div>
      <div class="right">
        <div class="menu flex justify-center items-center mr-80">
          <template v-for="menu in menus" :key="menu.id">
            <div
              class="menu-item"
              :class="{ active: route.path.includes(menu.path) }"
              v-if="userStore.getToken || menu.id != 5"
              @click="handleSelect(menu)"
            >
              {{ menu.name }}
            </div>
          </template>
        </div>
        <div
          v-if="!userStore.getUserInfo"
          class="button flex justify-center items-center"
        >
          <div class="button-item mr-8 cursor-pointer" @click="handleLogin"
            >登录</div
          >
          /
          <div
            class="button-item ml-8 cursor-pointer"
            @click="handleLogin('register')"
            >注册</div
          >
        </div>
        <div v-else class="flex items-center">
          <div
            class="p-2 bg-#fff borderRadius-50 flex justify-center items-center mr-8"
          >
            <img
              :src="
                (userStore.getUserInfo && userStore.getUserInfo.face) ||
                'https://res.hangxintong.cn/uniXkb/share/hqt-logo.jpg'
              "
              class="w-40 h-40 borderRadius-50"
            />
          </div>

          <el-dropdown trigger="click">
            <span
              class="el-dropdown-link color-#fff fs-14 cursor-pointer flex items-center gap-x-8"
            >
              {{ userStore.getUserInfo.name || userStore.getUserInfo.mobile }}
              <el-icon color="#fff" size="20"><CaretBottom /></el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  icon="User"
                  @click="router.push('/user/userInfo')"
                >
                  个人中心
                </el-dropdown-item>
                <el-dropdown-item icon="SwitchButton" @click="logOut"
                  >退出登录</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
    <CommonAffix />
    <Transition name="fade">
      <LightLogin
        :type="loginType"
        v-if="userStore.getShowLoginStatus"
      ></LightLogin>
    </Transition>
  </div>
</template>

<script setup>
import router from '@/router'
import { useUserStore } from '@/store/modules/user'
const route = useRoute()
const userStore = useUserStore()
const loginType = ref('weChat')
const handleSelect = menu => {
  router.push({ path: menu.path })
}
const menus = ref([
  {
    id: '1',
    name: '首页',
    path: '/dashboard',
  },
  {
    id: '2',
    name: '购买课程',
    path: '/course',
  },
  {
    id: '3',
    name: '模拟考试',
    path: '/exam',
  },
  {
    id: '4',
    name: '学考中心',
    path: '/studyCenter',
  },
  {
    id: '5',
    name: '个人中心',
    path: '/user',
  },
])

// 登录、注册
function handleLogin(key) {
  if (key == 'register') {
    loginType.value = 'register'
  } else {
    loginType.value = 'weChat'
  }
  userStore.setShowLogin(true)
}
// 退出登录
function logOut() {
  userStore.logout()
  router.push('/')
}
watch(
  () => userStore.getShowLoginStatus,
  () => {
    if (!userStore.getShowLoginStatus) {
      // 关闭轻登录 设置为账号登录
      loginType.value = 'pwd'
    }
  }
)
</script>

<style scoped lang="scss">
.header {
  position: sticky;
  top: 0;
  z-index: 2001;
  background: linear-gradient(
    270deg,
    #7ce9ff 0%,
    #166af7 20%,
    #166af7 80%,
    #77e4ff 100%
  );
  height: 88px;
  box-shadow: 0 3px 12px 0 rgba(0, 36, 153, 0.06);
  .content {
    width: 1360px;
    padding-right: 20px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .menu {
        .menu-item {
          padding: 5px 18px;
          border-radius: 4px;
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          margin-right: 24px;
          cursor: pointer;
        }
        .active {
          background-color: #fff;
          color: rgba(0, 96, 239, 1);
        }
      }
      .button {
        background-color: #fff;
        border-radius: 16px;
        padding: 6px 11px;
        color: rgba(30, 118, 244, 1);
        .button-item {
          &:hover {
            opacity: 0.75;
          }
        }
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: top 0.3s;
}
.fade-enter,
.fade-leave-active {
  top: -100vh;
}
</style>
