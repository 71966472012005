<template>
  <div class="w-1360 mx-auto mt-12 bg-white">
    <div
      class="text-#333333 px-24 fontSize-18 flex items-center justify-between h-68 border-b-1 border-solid border-#D8D8D8 border-x-0 border-t-0"
    >
      <span>新闻资讯</span>
      <el-button type="primary" plain @click="router.go(-1)">返回</el-button>
    </div>
    <div>
      <div class="h-64 flex items-center justify-between bg-white pl-23 pr-24">
        <div class="text-#3D3D3D fontSize-20 fontWeight-700"></div>
        <div>
          <el-input
            v-model="newsParams.title"
            style="width: 280px"
            placeholder="搜索关键字"
          >
            <template #append>
              <el-button
                type="primary"
                style="
                  background: #0060ef;
                  color: #fff;
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                "
                @click="search"
                >搜索</el-button
              >
            </template>
          </el-input>
        </div>
      </div>
      <div
        v-for="(item, index) in newsList"
        :key="index"
        class="flex items-center px-23 cursor-pointer mb-20 hover:text-#0060EF"
        @click="jump(item)"
      >
        <div class="w-8 h-8 borderRadius-50 bg-#333333 mr-10 shrink-0"></div>
        <div class="flex-1 text-ellipsis-1 mr-20">
          {{ item.title || '--' }}
        </div>
        <div class="ml-auto shrink-0">{{ splitStr(item.createTime) }}</div>
      </div>
    </div>
    <div
      class="mb-15 h-52 flex items-center justify-between px-21 border-4 border-x-0 border-b-0 border-solid border-#F1F4FF"
    >
      <span>共 {{ total }} 条数据</span>
      <el-pagination
        background
        :page-size="newsParams.pageSize"
        layout="prev, pager, next"
        :total="total"
        @change="change"
      />
    </div>
  </div>
</template>

<script setup>
import { getInformationByAgentId } from '@/api/home'
import { useUserStore } from '@/store/modules/user'
const userStore = useUserStore()
const router = useRouter()
const total = ref(0)
const newsParams = ref({
  pageNum: 1,
  pageSize: 15,
  title: '',
  agentId: userStore.agentInfo.id,
})
// 列表
const newsList = ref([])
onMounted(() => {
  getList()
})
function getList() {
  getInformationByAgentId(newsParams.value).then(res => {
    newsList.value = res.data.list || []
    total.value = res.data.total
  })
}

function change(page) {
  newsParams.value.pageNum = page
  getList()
}

function splitStr(date) {
  if (!date) return date
  return date.split(' ')[0]
}

function jump(item) {
  router.push('/informationDetail?id=' + item.id)
}
// 搜索
function search() {
  newsParams.value.pageNum = 1
  getList()
}
</script>

<style scoped lang="scss"></style>
