import UserLayout from '@/layout/userLayout.vue'

export default [
    {
        path: 'user',
        name: 'user',
        component: UserLayout,
        redirect: '/user/userInfo',
        meta: {title: '个人中心', affix: true, demoTitle: '建筑行业职业技能服务平台'},
        children: [
            {
                path: '/user/userInfo',
                name: 'userInfo',
                component: () => import('@/views/user/userInfo.vue'),
                meta: {title: '个人中心', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/registration',
                name: 'registration',
                component: () => import('@/views/user/registration.vue'),
                meta: {title: '报名申请列表', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/registration/registrationInfo',
                name: 'registrationInfo',
                component: () => import('@/views/user/registrationInfo.vue'),
                meta: {title: '报名申请详情', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/unpaid',
                name: 'unpaid',
                component: () => import('@/views/user/unpaid.vue'),
                meta: {title: '待支付', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/paid',
                name: 'paid',
                component: () => import('@/views/user/paid.vue'),
                meta: {title: '已完成', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/refund',
                name: 'refund',
                component: () => import('@/views/user/refund.vue'),
                meta: {title: '已退款', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/support',
                name: 'support',
                component: () => import('@/views/user/support.vue'),
                meta: {title: '技术支持', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/myCollection',
                name: 'myCollection',
                component: () => import('@/views/user/myCollection.vue'),
                meta: {title: '我的收藏', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/myCert',
                name: 'myCert',
                component: () => import('@/views/user/myCert.vue'),
                meta: {title: '我的证书', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/myVerCard',
                name: 'myVerCard',
                component: () => import('@/views/user/myVerCard.vue'),
                meta: {title: '我的学时审验卡', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/feedback',
                name: 'feedback',
                component: () => import('@/views/user/feedback.vue'),
                meta: {title: '意见反馈', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/myInvoice',
                name: 'myInvoice',
                component: () => import('@/views/user/myInvoice.vue'),
                meta: {title: '我的发票', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/InvoiceDetails',
                name: 'InvoiceDetails',
                component: () => import('@/views/user/InvoiceDetails.vue'),
                meta: {title: '发票详情', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/ApplyForInvoicing',
                name: 'ApplyForInvoicing',
                component: () => import('@/views/user/ApplyForInvoicing.vue'),
                meta: {title: '申请开票', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/InvoiceSubmission',
                name: 'InvoiceSubmission',
                component: () => import('@/views/user/InvoiceSubmission.vue'),
                meta: {title: '开票提交', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/InvoiceResults',
                name: 'InvoiceResults',
                component: () => import('@/views/user/InvoiceResults.vue'),
                meta: {title: '开票结果', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/InvoiceHeader',
                name: 'InvoiceHeader',
                component: () => import('@/views/user/InvoiceHeader.vue'),
                meta: {title: '发票抬头', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/myCardRoll',
                name: 'myCardRoll',
                component: () => import('@/views/user/myCardRoll.vue'),
                meta: {title: '我的卡券', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/myCert/myCertInfo',
                name: 'myCertInfo',
                component: () => import('@/views/user/myCertInfo.vue'),
                meta: {title: '我的证书详情', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/paid/orderInfo',
                name: 'orderInfo',
                component: () => import('@/views/user/orderInfo.vue'),
                meta: {title: '订单详情', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            },
            {
                path: '/user/paid/exchange',
                name: 'exchange',
                component: () => import('@/views/user/exchange.vue'),
                meta: {title: '批量购课兑换', affix: true, demoTitle: '建筑行业职业技能服务平台'},
            }
        ]
    }
]
