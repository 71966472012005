<template>
  <div class="w-1360 mx-auto my-20">
    <div
      class="flex justify-between items-center box-border px-24 py-13 color-#3D3D3D bg-#fff fs-16 mb-12"
    >
      企业批量购课
      <el-button plain @click="router.go(-1)">返回</el-button>
    </div>
    <div class="py-20 px-24 box-border bg-#fff">
      <div class="color-#333 fs-20 my-20 fw-700 text-center">{{
        content.noticeTitle
      }}</div>
      <div
        class="flex justify-between items-center py-12 box-border color-#999"
        style="border-bottom: 1px solid #d8d8d8"
      >
        <span>发布时间： {{ content.createTime }}</span>
        <!-- <span>点击量： 122</span> -->
      </div>
      <div class="ql-container ql-snow content-box">
        <div
          class="ql-editor px-20 py-20 my-20"
          style="min-height: 300px"
          v-html="content.noticeContent"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getSystemNotice } from '@/api/home'
const router = useRouter()
const content = ref({})

onMounted(() => {
  getSystemNotice().then(res => {
    content.value = res.data.list[0]
  })
})
</script>

<style scoped lang="scss">
:deep(.el-table__header) {
  background: #f2f7fe;
}
</style>
