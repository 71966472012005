import http from '@/libs/http/index'

/**
 * @description 分页检索培训资源池列表
 * @param {number} pageNum 页数
 * @param {number} pageSize 条数
 * @param {string} name 关键字
 * @param {string} orderByCreateTime 排序:'desc' 时间倒序  'asc': 正序
 *
 * @return {Object}
 */
export function courseListApi(data) {
  console.log(data)
  const newData = Object.assign({}, data)
  newData.tagIdList = data.tagIdList.join(',')
  return http.get('pc/training/pageList', { params: newData })
}

// /**
//  * @description 根据id获取培训项目信息
//  * @param {number} id
//  * @return {Object}
//  */
// export function courseInfoApi(id) {
//   return http.get('h5/training/' + id)
// }

// /**
//  * @description 获取培训项目分类树
//  * @param {number} id
//  * @return {Object}
//  */
// export function categoryListApi(id) {
//   return http.get('h5/training/category/list?parentId=' + id)
// }

/**
 * @description 根据项目配置id获取项目下的相关课程详情（非登录）
 * @param {number} id
 * @return {Object}
 */
export function getTrainCourseInfoApi(id) {
  return http.get('pc/training/trainCourseInfo?itemId=' + id)
}

/**
 * @description 根据项目配置id获取项课程列表（非登录）
 * @param {number} id
 * @return {Object}
 */
export function getCourseListApi(id) {
  return http.get('pc/training/getCourseList?trainId=' + id)
}

/**
 * @description 收藏课程
 * @param {String} resourceType
 * @param {String} resourceId
 * @return {Object}
 */
export function userCollectApi(data) {
  return http.post('pc/casualUserCollect', data)
}

/**
 * @description 检查指定培训项目是否购买
 * @param { String } trainItem
 */
export function getPurchasedStateApi(trainItem) {
  return http.get(`pc/training/purchasedState?trainItem=${trainItem}`)
}
/**
 * @description 获取标签列表
 * @param {Number} type  3-培训   4-考试
 */
export function getTagListApi(agentId) {
  return http.get(`/pc/trainItem/tags`, {
    params: {
      agentId,
    },
  })
}

/**
 * @description 获取课程分类树
 */
export function getTrainTreeApi() {
  return http.get(`pc/training/tree`)
}
/**
 * @description 部署课程、查询是否允许学习
 * @param {String} orderItemId
 * @param {String} taskId
 */
export function queryCourseApi({ orderItemId, taskId }) {
  return http.get('pc/training/direct/preStudy', {
    params: { orderItemId, taskId },
  })
}
/**
 * 购买课程筛选条件列表
 * @returns
 */
export function courseFilterListApi(agentId) {
  return http.get('pc/training/category/tree', {
    params: {
      agentId,
    },
  })
}
