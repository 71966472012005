import http from '@/libs/http/index'
/**
 * @description 学时查询列表
 */
export function getTrainItemListApi(data) {
  return http.get(`pc/training/getTrainItemList`, { params: data })
}

/**
 * @description 学时查询详情
 */
export function getTrainItemDetailApi(id) {
  return http.get(`pc/training/getTrainItemDetail/${id}`)
}

/**
 * @description 企业批量购课
 * @param { String } noticeType
 */
export function getSystemNotice() {
  return http.get(`pc/notice/pageList?noticeType=3`)
}

/**
 * @description 最近再学列表
 * @param { String } fetchNum  条数
 */
export function getRecentTrainingRecords(fetchNum) {
  return http.get(`pc/studyLog/recentTrainingRecords?fetchNum=${fetchNum}`)
}

/**
 * @description 学时打印列表
 */
export function getClassHourListApi(data) {
  return http.get('pc/training/learningProgressList', { params: data })
}
/**
 * @description 学时打印详情
 * @param { String } id
 */
export function getClassHourDetailApi(id) {
  return http.get('pc/training/learningProgress/' + id)
}

/**
 * @description 获取登录二维码
 */
export function getLoginQrCode() {
  return http.get('pc/user/login/qrCode/get')
}

/**
 * @description 检查登录二维码扫码状态
 * @param { String } sceneId  场景id
 */
export function checkLoginQrCodeState(sceneId) {
  return http.get('pc/user/login/qrCode/rotation?sceneId=' + sceneId)
}

/**
 * @description 根据代理商id获取新闻资讯
 * @param { String } agentId  代理商id
 */
export function getInformationByAgentId(data) {
  return http.get('/pc/information/list', { params: data })
}
/**
 * @description 获取新闻资讯详情
 * @param { String } id 资讯id
 */
export function getInformationById(id) {
  return http.get('/pc/information/' + id)
}
