export default [
    {
        path: '/placeOrder',
        name: 'placeOrder',
        component: () => import('@/views/order/placeOrder.vue'),
        meta: {title: '下单', affix: true, demoTitle: '建筑行业职业技能服务平台'},
    },
    {
        path: '/orderDetail',
        name: 'orderDetail',
        component: () => import('@/views/order/orderDetail.vue'),
        meta: {title: '订单详情', affix: true, demoTitle: '建筑行业职业技能服务平台'},
    },
    {
        path: '/orderStatus',
        name: 'orderStatus',
        component: () => import('@/views/order/orderStatus.vue'),
        meta: {title: '支付成功', affix: true, demoTitle: '建筑行业职业技能服务平台'},
    }
]
